@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Figtree:wght@400;700&family=Manrope&display=swap');



html, body {
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome & Safari */
}

.spinner {
  --clr: rgb(248, 199, 124);
  --gap: 6px;
    /* gap between each circle */
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
 }
 
 .spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
 }
 
 .spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
 }
 
 .spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
 }
 
 .spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
 }
 
 @keyframes fade {
  0%, 100% {
   opacity: 1;
  }
 
  60% {
   opacity: 0;
  }
 }